import { getTomorrow } from '@/modules/b2b/services/libs/helper';
import { DEFAULT_CARD } from '@/modules/b2b/constants';

export const getCartItems = (groupedTenant) => {
  const cartItems = [];
  const tenantIds = [];
  const listCards = [];

  Object.values(groupedTenant).map((items, index) => {
    const tenantId = parseInt(items[0].tenant_id);
    listCards.push(DEFAULT_CARD);
    tenantIds.push(tenantId);

    cartItems[index] = {
      tenantId,
      deliveryDate: getTomorrow(),
      deliveryTime: '',
      description: '', // order mark
      poNumber: '',
      stripeCustomerId: '',
      stripeCardId: '',
      cartItemIds: []
    };
  });
  return { cartItems, tenantIds, listCards };
};
