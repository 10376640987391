<template>
  <div class="cnt-modal">
    <div>
      <ion-label class="fw-600 fs-3">{{ $t('add_product_to_favorite') }}</ion-label>
    </div>
    <div class="mt-3 text-center">
      <ion-label>{{ $t('some_items_not_favorite') }}</ion-label>
    </div>
    <div class="mb-3 text-center">
      <ion-label>{{ $t('add_items_to_favorite_tab') }}</ion-label>
    </div>
    <ion-button expand="block" class="w-100" size="small" @click="onYes">{{ $t('yes') }}</ion-button>
    <ion-button expand="block" class="w-100" size="small" color="grey6" @click="$emit('close-modal')">{{
      $t('no')
    }}</ion-button>
  </div>
  <ion-loading
    :is-open="isOpenRef"
    cssClass="custom-loading"
    message=""
    spinner="crescent"
    @didDismiss="setOpen(false)"
  >
  </ion-loading>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { ACTIONS as ACTIONS_PRODUCT } from '@/modules/b2b/store/product/actions';
import { createNamespacedHelpers } from 'vuex';

const { mapActions: mapActionsProduct } = createNamespacedHelpers('b2b/product');

export default defineComponent({
  props: {
    data: {
      type: Object || null,
      required: true
    }
  },

  setup() {
    // loading
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    return {
      isOpenRef,
      setOpen
    };
  },

  emits: ['close-modal'],

  methods: {
    ...mapActionsProduct([ACTIONS_PRODUCT.ADD_FAVORITE]),
    async onYes() {
      this.setOpen(true);
      await this[ACTIONS_PRODUCT.ADD_FAVORITE]({
        item: this.data
      });
      this.setOpen(false);
      this.$emit('close-modal');
    }
  }
});
</script>
<style lang="scss" scoped>
.cnt-modal {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>
