<template>
  <div>
    <a hidden :href="phone"
      ><ion-button id="btn-contact">{{ $t('contact') }} {{ message.supplier_name }}</ion-button></a
    >
  </div>
</template>
<script>
import { alertController } from '@ionic/vue';
export default {
  props: ['message'],
  data() {
    return {
      phone: ''
    };
  },
  mounted() {
    this.handleGetInformation();
    this.presentAlertConfirm();
  },
  methods: {
    handleGetInformation() {
      this.phone = `${'tel:+' + this.message.supplier_phone}`;
    },
    async presentAlertConfirm() {
      const alert = await alertController.create({
        backdropDismiss: false,
        cssClass: 'alert-blacklist-order',
        mode: 'ios',
        header: this.$t('unable_to_place_order'),
        message: `Sorry, we are unable to place this order for ${this.message.customer_name} as there are <br> outstanding
        payments due to ${this.message.supplier_name}. Please contact +${this.message.supplier_phone} for more
        information.`,
        buttons: [
          {
            text: ` ${this.$t('contact')} ${this.message.supplier_name}`,
            handler: () => {
              document.getElementById('btn-contact').click();
            }
          },
          {
            text: this.$t('later'),
            id: 'confirm-button',
            handler: () => {
              this.$emit('on-no');
            }
          }
        ]
      });
      return alert.present();
    }
  }
};
</script>
