<template>
  <div class="paynow-instruction-modal">
    <div>
      <ion-img :src="PAYMENT_STATUS_ICONS.PENDING_WHITE" class="payment-status-icon" />
    </div>
    <div class="my-3 text-center">
      <ion-label>{{ $t('orderB2b.paynowInstruction') }}</ion-label>
    </div>
    <ion-button
      expand="full"
      size="default"
      class="w-100"
      @click="$emit('on-ok')"
      fill="solid"
      color="primary"
      >{{ $t('OK') }}</ion-button
    >
  </div>
</template>
<script>
import { PAYMENT_STATUS_ICONS } from '@/modules/b2b/constants';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {
      PAYMENT_STATUS_ICONS
    };
  },
  emits: ['on-ok']
});
</script>
<style lang="scss" scoped>
.paynow-instruction-modal {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.payment-status-icon {
  height: 50px;
  width: 50px;
}
ion-button {
  position: fixed;
  bottom: -5px;
}
</style>
