import dayjs from 'dayjs';

export const calDueDate = (creditTerm, deliveryDate) => {
  let result = dayjs();
  // const DATE_FORMAT = 'YYYY-MM-DD';
  if (creditTerm === 0) {
    // we re-formatted it as the default date value, just to be sure
    result = dayjs(deliveryDate);
  } else if (creditTerm === 7) {
    // Set it to the end of next week sunday
    result = dayjs(deliveryDate).day(7).add(7, 'day');
  } else if (creditTerm === 8) {
    // Set it to next Thursday. (Sunday is last day of week)
    result =
      dayjs(deliveryDate).day() === 0 ? dayjs(deliveryDate).day(2) : dayjs(deliveryDate).day(2).add(7, 'day');
  } else if (creditTerm === 15) {
    // If delivery date falls on 1 ~ 15th --> due on 30th on same month
    // If delivery date 16th ~ end of month --> due on 15th on next month
    result =
      dayjs(deliveryDate).get('date') <= 15
        ? dayjs(deliveryDate).endOf('month')
        : dayjs(deliveryDate).add(1, 'month').set('date', 15);
  } else if (creditTerm === 30) {
    // Set it to the end of the next month
    result = dayjs(deliveryDate).add(1, 'month').endOf('month');
  } else {
    // Add it as usual
    result = dayjs(deliveryDate).add(creditTerm || 0, 'day');
  }
  return result.format('ddd, DD MMM YYYY');
};
