<template>
  <div class="d-flex flex-row" @click="setOpen(true)">
    <ion-label v-if="!isViewOrder" class="text">
      {{ $t('catch_weight') }}
    </ion-label>
    <ion-text :class="!isViewOrder ? 'icon' : 'icon-for-view-oder'" color="primary">
      {{ '?' }}
    </ion-text>
  </div>
  <ion-popover
    :is-open="isOpenRef"
    :event="event"
    :translucent="true"
    @didDismiss="setOpen(false)"
    cssClass="popover"
    mode="ios"
  >
    <ion-content :scroll-y="false">
      <div class="pt-1">
        <div class="head">
          {{ $t('catch_weight') }}
        </div>
        <ion-text>
          <p class="text-center mt-0 mx-2">
            {{ $t('tooltip_message_start') }}
            {{ $t('tooltip_message_second') }}
            <b>{{ $t('weight_size') }}</b>
            {{ $t('and') }}
            <b>{{ $t('price_normal') }}</b>
            {{ $t('tooltip_message_third') }}
            {{ $t('tooltip_message_four') }}
            {{ $t('tooltip_message_end') }}
          </p>
        </ion-text>
        <div class="grey-lines"></div>
        <div>
          <ion-button size="small" class="text-primary" expand="block" fill="clear" @click="setOpen(false)">
            {{ $t('OK') }}
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-popover>
</template>
<script>
import { closeOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    message: {
      type: String,
      default: ''
    },
    isViewOrder: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const isOpenRef = ref(false);
    const event = ref();
    const setOpen = (state, ev) => {
      event.value = ev;
      isOpenRef.value = state;
    };
    return { isOpenRef, setOpen, event, closeOutline };
  }
});
</script>
<style lang="scss" scoped>
.text {
  font-size: 10px;
  color: #757575;
}
.icon {
  background-color: #eb8c31;
  padding: 0px 5.5px;
  border-radius: 100%;
  margin-left: 3px;
  color: white;
  font-size: 10px;
  font-weight: bold;
}
.icon-for-view-oder {
  background-color: #eb8c31;
  padding: 1px 6px;
  border-radius: 100%;
  margin-left: 8px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.head {
  width: 100%;
  text-align: center;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 8px;
}
.popover {
  --width: 75vw;
}
b {
  color: var(--primary-yellow-500, #eb8c31);
}
.grey-lines {
  height: 1px;
  width: 100%;
  background: #e0e0e0;
  background-color: #e0e0e0;
}
</style>
